import React, { useEffect, useState } from 'react'
import { Navbar as UikitMenu, useModal } from '@luthebao/uikit'
// import { Navbar as UikitMenu, useModal } from 'uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import useTheme from 'hooks/useTheme'
import { ContextApi } from 'contexts/Localization/types'
import { useBananaPrice } from 'state/tokenPrices/hooks'
import { useTranslation } from 'contexts/Localization'
import { useProfile, useLiveIfoStatus } from 'state/hooks'
import useSelectNetwork from 'hooks/useSelectNetwork'
import track from 'utils/track'
import bscConfig from './chains/bscConfig'
import { languageList } from '../../config/localization/languages'
import { MYCOLOR } from 'config'

const Menu = (props) => {
  const { account, chainId } = useActiveWeb3React()
  const { login, logout } = useAuth()
  const { switchNetwork } = useSelectNetwork()
  const { isDark } = useTheme()
  const { t, setLanguage, currentLanguage } = useTranslation()
  const { liveIfos } = useLiveIfoStatus()

  const currentMenu = (translate: ContextApi['t']) => {
    
    return bscConfig(translate)
  }

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      t={t}
      langs={languageList}
      setLang={setLanguage}
      currentLang={currentLanguage.language}
      links={currentMenu(t)}
      chainId={chainId}
      switchNetwork={switchNetwork}
      track={track}
      logourl={"/logox.png"}
      liveResult={liveIfos}
      iframe={false}
      navColor={MYCOLOR.menu}
      {...props}
    />
  )
}

export default Menu

import { baseUrlStrapi } from 'hooks/api'
import { LaunchCalendarCard } from 'state/types'

const getHomepageLaunchCalendar = async (): Promise<LaunchCalendarCard[]> => {
  try {
    // const response = await fetch(`${baseUrlStrapi}/home-v-2-launch-calendars?_sort=launchTime:asc`)
    // const launchRes = await response.json()
    // if (launchRes.statusCode === 500) {
    //   return null
    // }
    return null
  } catch (error) {
    return null
  }
}

export default getHomepageLaunchCalendar

/** @jsxImportSource theme-ui */
import { Link, useHistory } from 'react-router-dom'
import { AutoRenewIcon, CogIcon, Flex, Text, useModal } from '@luthebao/uikit'
import { ChainId } from '@apeswapfinance/sdk'
import { useTranslation } from 'contexts/Localization'
import React from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import SettingsModal from '../../../../components/Menu/GlobalSettings/SettingsModal'
import { styles } from './styles'

const DexNav = ({ onUserRefresh }: {
  onUserRefresh?: any
}) => {
  const { t } = useTranslation()
  const { pathname } = useHistory().location
  const { chainId } = useActiveWeb3React()

  const onLiquidity =
    pathname?.includes('add-liquidity') ||
    pathname?.includes('liquidity') ||
    pathname?.includes('remove') ||
    pathname?.includes('find')

  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex sx={styles.dexNavContainer}>
      <Flex
        sx={{ ...styles.navLinkContainer, justifyContent: chainId === ChainId.BSC ? 'space-between' : 'flex-start' }}
      >
        <Text
          size="14px"
          sx={{
            ...styles.navLink,
            color: !pathname?.includes('swap') && 'textDisabled',
            mr: chainId === ChainId.BSC ? '0px' : '30px',
          }}
          as={Link}
          to="/swap"
          id="swap-link"
          className="swap"
        >
          {t('Swap')}
        </Text>

        <Text
          size="14px"
          sx={{ ...styles.navLink, color: !onLiquidity && 'textDisabled' }}
          as={Link}
          to="/swap"
          id="liquidity-link"
          className="liquidity"
        >
          {t('Liquidity')}
        </Text>
      </Flex>
      <Flex sx={{ ...styles.navIconContainer }}>
        <AutoRenewIcon sx={{ cursor: 'pointer' }} onClick={onUserRefresh} />
        <CogIcon sx={{ cursor: 'pointer' }} onClick={onPresentSettingsModal} />
      </Flex>
    </Flex>
  )
}

export default React.memo(DexNav)

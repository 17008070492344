import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import useEagerConnect from 'hooks/useEagerConnect'
import { ResetCSS, ApeSwapTheme } from '@luthebao/uikit'
import BigNumber from 'bignumber.js'
import { useFetchProfile, useUpdateNetwork, useFetchLiveIfoStatus, useFetchLiveTagsAndOrdering } from 'state/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import Swap from './views/Dex/Swap'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
})

declare module '@emotion/react' {
  export interface Theme extends ApeSwapTheme {
  }
}

// Route-based code splitting

const NotFound = lazy(() => import('./views/NotFound'))
const AdminPools = lazy(() => import('./views/AdminPools'))

const redirectSwap = () => import('./views/Dex/Swap/redirects')
const RedirectPathToSwapOnly = lazy(async () =>
  redirectSwap().then((r) => ({
    default: r.RedirectPathToSwapOnly,
  })),
)
const RedirectToSwap = lazy(async () =>
  redirectSwap().then((r) => ({
    default: r.RedirectToSwap,
  })),
)

const redirectAddLiquidity = () => import('./views/Dex/AddLiquidity/redirects')
const RedirectDuplicateTokenIds = lazy(async () =>
  redirectAddLiquidity().then((r) => ({
    default: r.RedirectDuplicateTokenIds,
  })),
)
const RedirectOldAddLiquidityPathStructure = lazy(async () =>
  redirectAddLiquidity().then((r) => ({
    default: r.RedirectOldAddLiquidityPathStructure,
  })),
)
const RedirectToAddLiquidity = lazy(async () =>
  redirectAddLiquidity().then((r) => ({
    default: r.RedirectToAddLiquidity,
  })),
)

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  useUpdateNetwork()
  useEagerConnect()
  usePollBlockNumber()
  useFetchProfile()
  useFetchLiveIfoStatus()
  useFetchLiveTagsAndOrdering()

  const loadMenu = () => {
    return (
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact component={RedirectPathToSwapOnly} />
            <Route path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
    )
  }

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      {loadMenu()}
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)